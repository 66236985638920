import "./components_style.css"
import React from 'react'


function Navbar({name}) {
  return (
    <header id="navbar-container">
      <nav id="navbar">
        <a href="/dashboard"><h1>UCL Groupies</h1></a>
        <div id="powered-by-container">
            <h2>Powered by UCL API</h2>
            <img src="/favicon.svg"/>
        </div>
      </nav>
    </header>
  )
}

export default Navbar
