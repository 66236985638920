import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Login_page from "./pages/login_page/Login_page";
import Dashboard_page from "./pages/dashboard_page/Dashboard_page";
import Joined_groups_page from "./pages/joined_groups_page/Joined_groups_page";
import View_group_page from "./pages/view_group_page/View_group_page";
import Select_module_page from "./pages/select_module_page/Select_module_page";
import Find_group_page from "./pages/find_group_page/Find_group_page";
import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";
import Create_new_group from "./pages/create_new_group/Create_new_group";

const root = ReactDOM.createRoot(document.getElementById("root"));
const router = createBrowserRouter([
  {
    path: "/",
    element: <Login_page />,
  },
  {
    path: "/dashboard",
    element: <Dashboard_page />,
  },
  {
    path: "/new_group",
    element: <Create_new_group />,
  },
  {
      path: "/view_groups",
      element: <Joined_groups_page />
  },
  {
      path: "/view_groups/hauisdhuauidh378y734h78hasui",
      element: <View_group_page />
  },
  {
      path: "/find_group",
      element: <Select_module_page />
  },
  {
      path: "/find_group/COMP0147",
      element: <Find_group_page />
  }
]);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
