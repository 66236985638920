import React from "react";
import Navbar from "../../components/Navbar";
import "./styles.css";

function View_group_page() {
  return (
    <div className="view_group_page">
      <Navbar />
      <div class="postit">
        <h3>Max Kanovich Thinkers</h3>
        <p>We study Kanovich Thought.</p>
      </div>
      <div id="group-container">
        <section id="timetable">
          <div class="pink postit">
            <h3>Group Availability</h3>
            <table>
                <tr>
                    <th>Time</th>
                    <th>Monday</th>
                    <th>Tuesday</th>
                    <th>Wednesday</th>
                    <th>Thursday</th>
                    <th>Friday</th>
                </tr>
                <tr>
                    <td>09:00</td>
                    <td class="timeslot onebusy"></td>
                    <td class="timeslot onebusy"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                </tr>
                <tr>
                    <td>10:00</td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot twobusy"></td>
                    <td class="timeslot threebusy"></td>
                    <td class="timeslot twobusy"></td>
                </tr>
                <tr>
                    <td>11:00</td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                </tr>
                <tr>
                    <td>12:00</td>
                    <td class="timeslot threebusy"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot onebusy"></td>
                    <td class="timeslot"></td>
                </tr>
                <tr>
                    <td>13:00</td>
                    <td class="timeslot threebusy"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                </tr>
                <tr>
                    <td>14:00</td>
                    <td class="timeslot onebusy"></td>
                    <td class="timeslot twobusy"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                </tr>
                <tr>
                    <td>15:00</td>
                    <td class="timeslot"></td>
                    <td class="timeslot twobusy"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                </tr>
                <tr>
                    <td>16:00</td>
                    <td class="timeslot onebusy"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot threebusy"></td>
                </tr>
                <tr>
                    <td>17:00</td>
                    <td class="timeslot twobusy"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot threebusy"></td>
                </tr>
                <tr>
                    <td>18:00</td>
                    <td class="timeslot twobusy"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot threebusy"></td>
                </tr>
                <tr>
                    <td>19:00</td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot onebusy"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                </tr>
                <tr>
                    <td>20:00</td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot onebusy"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                </tr>
                <tr>
                    <td>21:00</td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                    <td class="timeslot"></td>
                </tr>
                <br />
            </table>
                <p>White means everyone's free. The darker the shade of red, the fewer people are free.</p>
          </div>
        </section>
        <section id="people">
          <div class="orange postit">
            <h3>Invite Link</h3>
            <a href="https://uclapi.applicationer.com/join/hauisdhuauidh378y734h78hasui">https://uclapi.applicationer.com/join/hauisdhuauidh378y734h78hasui</a>
            <h3>Members (3)</h3>
            <ul>
              <li>Suhas</li>
              <li>Ahmed</li>
              <li>Ollie</li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  );
}

export default View_group_page;
