import React from "react";
import "../styles.css";

function Input_box({ on_submit, options }) {
  return (
    <form
      className="input_box"
      onSubmit={(e) => {
        e.preventDefault();
        on_submit(e);
      }}>
      <div className="input_fields">
        <p> Enter your group name </p>
        <input placeholder="Group name" type="text" className="text_box" />
      </div>

      <div className="input_fields">
        <p> Select group module </p>
        <select placeholder="group name" type="text" className="text_box" >
            {options}


        </select>
      </div>

      <div className="input_fields">
        <p> Give a group description</p>
        <input placeholder="Description" type="text" className="text_box" />
      </div>
      <div className="check_boxes">
        <div className="input_fields_check_box">
          <input
            className="check_box"
            type="radio"
            value="public"
            name="group"></input>
          <p>Public group</p>
        </div>
        <div className="input_fields_check_box">
          <input
            className="check_box"
            type="radio"
            value="private"
            name="group"></input>
          <p>Private group</p>
        </div>
      </div>

      <div className="btn_box">
        <button className="btn btn_accept"> Confirm</button>
      </div>
    </form>
  );
}

export default Input_box;
