import React from "react";
import Navbar from "../../components/Navbar";
import "./styles.css";

function Dashboard_page() {
  return (
    <div className="dashboard_page">
      <Navbar />
      <section id="group-button-container">
        <div id="group-button-left">
          <a href="/new_group" class="new-group">
            <i className="fa fa-plus"></i>
            <p>New Group</p>
          </a>
      </div>
      <div id="group-button-right">
        <a href="/view_groups" class="view-groups">
          <i className="fa fa-list"></i>
          <p>View Joined Groups</p>
        </a>
      <br />
        <a href="/find_group" class="find-group">
          <i className="fa fa-search"></i>
          <p>Find a Group</p>
        </a>
      </div>
      </section>
    </div>
  );
}

export default Dashboard_page;
