import React from "react";
import "./styles.css";
import Navbar from "../../components/Navbar";
import Input_box from "./components/Input_box";
import Linkbox from "./components/Linkbox";

let link = "";
let group_options=[<option>COMP0147</option>, <option>COMP0002</option>, <option>ENGF0001</option>, <option>ENGF0002</option>]
async function on_submit(e) {
    window.location.href = "https://uclgroupies.applicationer.com/view_groups"
    
}
async function get_modules(){
      
}

function Create_new_group() {
  
  return (
    <>
      <Navbar />
      <section id="postits-container">
        {link === "" ? (
        <div class="postit">
            <h3>New Groupie</h3>
            <Input_box on_submit={on_submit} options={group_options}/>
        </div>
        ) : (
        <div class="pink postit">
            <Linkbox invite_link={link} options={group_options}/>
        </div>
        )}
      </section>
    </>
  );
}

export default Create_new_group;
