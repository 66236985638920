import React from 'react';
import Navbar from "../../components/Navbar";
import "./styles.css";

function Find_group_page() {
    return(
        <div className="find-groups-page">
          <Navbar />
          <section id="groups-section">
          <a href="/view_groups/hauisdhuauidh378y734h78hasui" class="module">
            <h3>Max Kanovich Thinkers</h3>
            <p>We study Kanovich Thought.</p>
          </a>

          <a href="/view_groups/asdauauhi4u474hduhaiudia" class="module">
            <h3>The Kanovichians</h3>
            <p>We are the true believers in Kanovichian philosophy</p>
          </a>
        </section>
        </div>
    );
}

export default Find_group_page;
