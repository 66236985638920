import React from 'react'
import "../styles.css"

function Linkbox({invite_link}) {
  return (
    <div class="linkbox">
      <h3>Your New Groupie</h3>
      <p>Name: Max Kanovich Fan Club</p>
      <p>Module: COMP0147 Discrete Maths</p>
      <p>Description: The greatest study group of all time</p>
      <h3>Invite Link</h3>
      <a href={invite_link}>{invite_link}</a>
      <br />
      <br />
    </div>
  )
}

export default Linkbox
