import React from 'react';
import Navbar from "../../components/Navbar";
import "./styles.css";


function Select_module_page() {
    return(
        <div className="select-module-page">
          <Navbar />
          <section id="groups-section">
          
          <a href="/find_group/COMP0147" class="module">
            <h3>COMP0147 Discrete Maths</h3>
          </a>
          <a href="/find_group/COMP0002" class="module">
            <h3>COMP0002 Principles of Programming</h3>
          </a>
          <a href="/find_group/ENGF0001" class="module">
            <h3>ENGF0002 Engineering Challenges</h3>
          </a>
          <a href="/find_group/ENGF0002" class="module">
            <h3>ENGF0002 Design and Professional Skills 1</h3>
          </a>
        </section>
        </div>
    );
}

export default Select_module_page;
