import React from "react";
import "./styles.css";
import Navbar from "../../components/Navbar";

function sign_in() {
  console.log("sign in");
}

function Login_page() {
  return (
    <div className="login_page">
      <Navbar />
      <section id="page-container">
        <section>
          <div class="ppostit">
            <h3>UCL Groupies</h3>
            <p>
              Made for the UCL API Hackathon by Ollie, Ahmed, Suhas and Kerem.
            </p>
          </div>
        </section>
        <section>
          <div class="ppink ppostit">
            <h3>About</h3>
            <p>
              This app was designed and made in 48 hours for the November 2022
              UCL API Hackathon. It's designed to help you create a private
              study group or find a public one for your modules, and gets
              everyone's timetable from the API to find the times when the most
              people are free. It's built with a React frontend and a Flask
              backend.
            </p>
          </div>
          <div class="oorange ppostit">
            <h3>How Does It Work?</h3>
            <p>
              Sign in with your UCL email below. You can then create a study
              group or find public ones for your modules, or go to the invite
              link you've been sent to join a private one.
            </p>
            <a
              href="https://uclapi.applicationer.com/login"
              class="signin-button">
              <img
                src="https://s3.eu-west-2.amazonaws.com/uclapi-static/SignInWithUCLSmall.png"
                width="100%"
                onClick={sign_in}
              />
            </a>
          </div>
        </section>
      </section>
    </div>
  );
}
<a href="#" class="signin-button">
  <img
    src="https://s3.eu-west-2.amazonaws.com/uclapi-static/SignInWithUCLSmall.png"
    width="100%"
    onClick={sign_in}
  />
</a>;

export default Login_page;
