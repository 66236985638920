import React from 'react';
import Navbar from "../../components/Navbar";
import "./styles.css";

function Joined_groups_page() {
    return(
        <div className="select-module-page">
          <Navbar />
          <section id="groups-section">
          <a href="/view_groups/hauisdhuauidh378y734h78hasui" class="module">
            <h3>Max Kanovich Thinkers</h3>
            <p>We study Kanovich Thought.</p>
            <p><strong>Members (3)</strong>: Suhas, Ahmed, Ollie</p>
          </a>

          <a href="/view_groups/hauisdhuasdadsauidh378y734h78hasui" class="module">
            <h3>Transition Mentor 11</h3>
            <p>random description</p>
            <p><strong>Members (1)</strong>: Suhas</p>
          </a>
        </section>

        </div>
    );
}

export default Joined_groups_page;
